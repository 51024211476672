import Background from "../Global/Background";
import Category from "../Global/Category";
import Link from "../Global/Link";
import Moment from "react-moment";
import PropTypes from "prop-types";
import React from "react";
import SmartText from "../../utils/TextHandler";

const NewsCard = (props) => {
    const { item, layout } = props;

    let news = item;
    let parent_id = null;
    let news_link = item;

    if (news.link) {
        news = item.link.content;
        parent_id = item.link.parent_id;
        news_link = item.link;
    } else {
        news = JSON.parse(item.content);
        parent_id = item.parent_id;
    }

    const news_title = news.preview_title || news.title;
    const news_preview = news.preview_body || news.body;
    const news_image = news.preview_image?.filename || news.image?.filename;

    if (!news_title) {
        return null;
    }

    return (
        <div
            className={`h-100 w-100 brand-background-white brand-text-black position-relative d-flex flex-row ${
                item.style === "side" ? "flex-lg-row" : "flex-lg-column"
            }`}
        >
            <div
                className={`brand-background-grey-lighter flex-shrink-0 col-4 ${
                    item.style === "side" ? "col-lg-6" : "col-lg-12"
                }`}
            >
                <Background
                    src={news_image}
                    sm="470x264"
                    md="470x264"
                    lg="470x264"
                    alt={item.title}
                    className="w-100 h-100 ratio ratio-16x9"
                />
            </div>
            <div className="flex-grow-1 p-3 p-lg-4">
                {parent_id && (
                    <p>
                        <Category id={parent_id} />
                    </p>
                )}

                {news_title && (
                    <h6 className="brand-font-uppercase brand-font-bold">
                        {news_title}
                    </h6>
                )}

                {news.date && (
                    <p className="brand-font-uppercase brand-font-bold brand-text-grey small d-none d-lg-block">
                        <Moment format="Do MMMM YYYY" date={news.date} />
                    </p>
                )}

                {news_preview && layout === "full" && (
                    <SmartText
                        method="text"
                        limit={!news.preview_body ? 80 : null}
                        className="d-none d-lg-block small"
                    >
                        {news_preview}
                    </SmartText>
                )}
            </div>
            <Link to={news_link} stretched />
        </div>
    );
};

export default NewsCard;

NewsCard.propTypes = {
    item: PropTypes.object,
    layout: PropTypes.string,
};

NewsCard.defaultProps = {
    item: null,
    layout: "full",
};
