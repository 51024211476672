export default function SmartColour(colour) {
  let response = {
    background: "white",
    text: "black",
  };
  // We need to respond with
  // - Background colour
  // - Font colour

  if (colour) {
    if (colour === "pink") {
      response = {
        background: "pink",
        text: "white",
      };
    } else if (colour === "red") {
      response = {
        background: "red",
        text: "white",
      };
    } else if (colour === "blue") {
      response = {
        background: "blue",
        text: "white",
      };
    } else if (colour === "green") {
      response = {
        background: "green",
        text: "white",
      };
    } else if (colour === "yellow") {
      response = {
        background: "yellow",
        text: "black",
      };
    } else if (colour === "purple") {
      response = {
        background: "purple",
        text: "white",
      };
    } else if (colour === "black") {
      response = {
        background: "black",
        text: "white",
      };
    } else if (colour === "gold") {
      response = {
        background: "gold",
        text: "black",
      };
    } else if (colour === "aqua") {
      response = {
        background: "aqua",
        text: "black",
      };
    } else if (colour === "white") {
      response = {
        background: "white",
        text: "black",
      };
    }

    return response;
  }
  return null;
}
