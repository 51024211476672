import { graphql, useStaticQuery } from "gatsby";

import Link from "./Link";
import PropTypes from "prop-types";
import React from "react";
import SmartColour from "../../utils/ColourHandler";

// ====

const Category = ({ blok, id, link }) => {
    const newsCategories = useStaticQuery(graphql`
        {
            allStoryblokEntry(
                filter: {
                    field_component: { in: ["news", "game"] }
                    is_startpage: { eq: true }
                }
            ) {
                nodes {
                    field_title_string
                    field_label_string
                    field_colour_string
                    parent_id
                    full_slug
                }
            }
        }
    `);

    let doc;
    if (newsCategories) {
        doc = newsCategories.allStoryblokEntry.nodes;
    } else {
        doc = blok;
    }

    return (
        <>
            {doc.map((node) => {
                if (node.parent_id === id) {
                    const colour = SmartColour(node.field_colour_string);

                    const classes = `badge text-decoration-none brand-badge brand-background-${colour.background} brand-text-${colour.text}`;
                    const label = node.field_label_string
                        ? node.field_label_string
                        : "News";

                    if (link) {
                        return (
                            <Link
                                key={`${id}-${new Date().getMilliseconds()}`}
                                to={node.full_slug}
                                className={classes}
                            >
                                {label}
                            </Link>
                        );
                    }
                    return (
                        <span
                            key={`${id}-${new Date().getMilliseconds()}`}
                            className={classes}
                        >
                            {label}
                        </span>
                    );
                }
                return null;
            })}
        </>
    );
};

export default Category;

// ====

Category.propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Category.defaultProps = {
    id: null,
};
